<div class="row main">
  <div class="col-xs-12 row-child no-padding">
    <div class="center-row">
      <div class="col-md-6 col-sm-12 col-xs-12 no-padding height-100 hidden-sm hidden-xs">
        <div class="vc_single_image-wrapper">
          <div class="height-100">
            <div class="image-wrapper">
              <div>
                <div class="content-heading">Welcome</div>
                <p class="content-banner">Effortlessly control and manage your Halo and Aura Devices.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-xs-12 hidden-md hidden-lg no-padding">
        <div class="image-container">
          <div class="img-content">
            <div class="mobile-content-heading">Welcome</div>
            <p class="mobile-content-banner">Effortlessly control and manage your Halo and Aura Devices.</p>
          </div>
        </div>

      </div>
      <div class="col-md-6 col-sm-12 col-xs-12 no-padding height-100 parent-class">
        <div class="height-100">
          <div class="sp-middle-element">
            <div class="row">
              <br class="mobile-break">
              <div>
                <div class="sp-middle-element col-xs-12">
                  <a #brandfocus tabIndex="1" (keydown.enter)="select('kwikset')" (click)="select('kwikset')" class="brand-select-button" id="kwikset">
                    <img src="../../../assets/images/kwikset-logo-medium.svg" alt="kwikset.svg" width="100%"
                      height="100%">
                  </a>
                </div>
                <div class="sp-middle-element col-xs-12">
                  <a tabIndex="2" (keydown.enter)="select('weiser')" (click)="select('weiser')" class="brand-select-button" id="weiser">
                    <img src="../../../assets/images/weiser-logo-medium.svg" alt="weiser-new.svg" width="100%"
                      height="100%">
                  </a>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row footer-top-border">
  <div class="col-md-12 no-padding footer-parent">
    <footer class="page-footer font-small teal pt-4">
      <div class="col-md-12 sp-footer no-padding">
        <div class="row sp-footer-row-1">
          <div class="col-xs-12 sp-footer-padding padding-remove">
            <div class="footer-content-wrapper" style="margin: 0 auto !important;">
              <div class="mobile-space"></div>
              <a tabIndex="3" class="footer_content anchor-padding" (keydown.enter)="showWebAccessibilty()" (click)="showWebAccessibilty()"> Web
                Accessibility</a>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 justify-content-md-center no-padding">
            <div class="mobile-screen-copy-rights">
              <p style="padding: 10px; margin-bottom: 0;" class="copy_rights">
                Copyright {{currentYear}} © ASSA ABLOY, Inc. All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</div>
<div class="sleek-fixed-footer"></div>