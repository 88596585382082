<div class="main">
  <div class="row">
    <div class="hidden-xs hidden-sm col-sm-6 no-padding image-container">
      <div class="gradient">
        <div>
          <div class="content-heading">{{ 'SECURE_HOME.WELCOME_TAG' | translate }}</div>
            <ng-container *ngIf="userBrand === 'kwikset'">
            <p class="content-banner">{{ 'BRAND_SELECTION.CONTENT_HEADING_KWIKSET' | translate }}</p>
            </ng-container>
            <ng-container *ngIf="userBrand === 'weiser'">
            <p class="content-banner">{{ 'BRAND_SELECTION.CONTENT_HEADING_WEISER' | translate }}</p>
            </ng-container>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-xs-12 hidden-md hidden-lg no-padding">
      <div class="image-container">
        <div class="img-content">
          <div class="mobile-content-heading">{{ 'SECURE_HOME.WELCOME_TAG' | translate }}</div>
            <ng-container *ngIf="userBrand === 'kwikset'">
            <p class="content-banner">{{ 'BRAND_SELECTION.CONTENT_HEADING_KWIKSET' | translate }}</p>
            </ng-container>
            <ng-container *ngIf="userBrand === 'weiser'">
            <p class="content-banner">{{ 'BRAND_SELECTION.CONTENT_HEADING_WEISER' | translate }}</p>
            </ng-container>
        </div>
      </div>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 no-padding right-content-container-parent">
      <div class="right-content-container">
        <div class="col-lg-12 col-md-12 no-padding">
          <div class="row">
            <div class="col-lg-12 col-sm-12 no-padding signIn-buttons">
              <button type="button" [routerLink]="['/home/login']" class="btn btn-block landing-btn-dark">
                {{'LOGIN.SIGNIN_WITH_EMAIL' | translate}}
              </button>
            </div>
            <div *ngIf="googleFederation" class="col-lg-12 col-sm-12 no-padding signIn-buttons">
              <button (click)="onFederatedLogin(federatedIdentities.Google)" type="button" class="btn btn-block btn-outline-dark">
                <img alt="gmail.png" class="gmail-img" height="18px" width="17.66px"
                  src="../../../assets/images/gmail-icon.png">
                <span class="gmail-btn-text">{{'LOGIN.SIGNIN_WITH_GOOGLE_BUTTON' | translate}}</span>
              </button>
            </div>
            <div *ngIf="appleFederation" class="col-lg-12 col-sm-12 no-padding signIn-buttons">
              <button (click)="onFederatedLogin(federatedIdentities.Apple)" type="button" class="btn btn-block btn-outline-dark">
                <img alt="gmail.png" class="gmail-img" height="18px" width="17.66px"
                  src="../../../assets/images/apple.svg">
                <span class="gmail-btn-text">{{'LOGIN.SIGNIN_WITH_APPLE_BUTTON' | translate}}</span>
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 no-padding account_text">
              <span>{{'LOGIN.NEW_ACCOUNT.PRE' | translate}}&nbsp;</span>
              <ng-container *ngIf="googleFederation || appleFederation">
                <a (click)="onRegister()" class="underline_text">{{'LOGIN.NEW_ACCOUNT.CLICK' |
                  translate}}</a><span>.</span>
              </ng-container>
              <ng-container *ngIf="!googleFederation && !appleFederation">
                <a (click)="onRegisterPage()" class="underline_text">{{'LOGIN.NEW_ACCOUNT.CLICK' |
                  translate}}</a><span>.</span>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>