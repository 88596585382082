import { NgRedux, select } from '@angular-redux/store';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ComponentCanDeactivate } from 'src/app/guards/deactivate/ComponentCanDeactivate';
import { IAppState } from 'src/app/services/utils/state-management/store';
import { ForgotPasswordService } from '../../../services/forgot-password.service';
import { IForgotPassword } from '../../../services/utils/state-management/types';
import { Utility } from '../../../services/utils/utility';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
})
export class ForgotPasswordComponent
  extends ComponentCanDeactivate
  implements OnInit
{
  forgotPassword;
  changed;
  errorMessage = null;
  isShowError: boolean = false;
  successMessage;
  image: string;
  securityQuestionsFlag: any;
  config: any;
  userBrand: string;

  constructor(
    public translate: TranslateService,
    private forgotPasswordService: ForgotPasswordService,
    private router: Router,
    private ngRedux: NgRedux<IAppState>
  ) {
    super();
  }
  canDeactivate() {
    let toastMsg = this.translate.instant('TOASTER.MESSAGE');
    let objectState = JSON.parse(localStorage.getItem('state'));
    if (
      objectState['successMessage'] === this.translate.instant('FORGOT_PASSWORD.ENTER_PASSWORD.PASSWORD_CHANGE_SUCCESS_MESSAGE')
    ) {
      return !this.changed;
    }
    if (objectState.forgotPassword.email === null) {
      return true;
    }
    else {
      return this.changed;
    }
  }

  ngOnInit() {
    Utility.focusLogo();
    this.image = './../../../assets/images/landing/Rectangle_794.jpg';
    let forgotPassword: IForgotPassword =
      this.forgotPasswordService.getForgotPasswordObject();
    if (!forgotPassword.email) {
      this.router.navigate(['/home/login']);
    }
    this.config = JSON.parse(localStorage.getItem('config'));
    this.securityQuestionsFlag = this.config.Feature.SecurityQuestions;
    if (this.securityQuestionsFlag === false) {
      forgotPassword.step = 2
    }
    this.ngRedux.select('forgotPassword').subscribe((value: any) => {
      this.forgotPassword = value;
    });
    this.userBrand = localStorage.getItem("userBrand");
  }

  addError(err) {
    if (err.hasOwnProperty('isShowError')) {
      this.errorMessage = err.errorMessage;
      this.isShowError = err.isShowError;
    } else {
      this.isShowError = true;
      this.errorMessage = err;
    }
  }
}
