import { NgRedux } from '@angular-redux/store';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FederatedManagementService } from 'src/app/services/federated-management.service';
import { UserRegistrationService } from 'src/app/services/user-registration.service';
import { Config } from 'src/app/services/utils/Config';
import { Constants } from 'src/app/services/utils/Constants';
import { UserManagement } from 'src/app/services/utils/state-management/action-classes';
import { IAppState } from 'src/app/services/utils/state-management/store';
import { SignupOptionsModalComponent } from '../../register/signup-options-modal/signup-options-modal.component';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit, OnDestroy {

  userBrand: string;
  image: string;
  isRegisterPopup: string;
  googleFederation: boolean = false;
  appleFederation: Boolean;

  federatedIdentities = Config.FEDERATED_IDENTITIES;

  constructor(
    private router: Router,
    public userManagementService: UserManagement,
    private registrationService: UserRegistrationService,
    ngRedux: NgRedux<IAppState>,
    private modalService: BsModalService,
    private federatedService: FederatedManagementService,
    ) {

    let configObject = JSON.parse(localStorage.getItem('config') || '');
    this.googleFederation = configObject && configObject.Feature.GoogleFederation;
    this.appleFederation = configObject && configObject.Feature.AppleFederation;
  }

  ngOnInit() {
    this.userBrand = localStorage.getItem("userBrand");
    this.image = "./../../../assets/images/landing/Rectangle_794.jpg";
    // resetting steps if the user comes back from the middle of registration steps
    this.registrationService.resetSteps();
    this.isRegisterPopup = localStorage.getItem('ISREGISTERPOPUP');
    if (this.isRegisterPopup && (this.googleFederation || this.appleFederation)) {
      localStorage.removeItem('ISREGISTERPOPUP');
     /*  this.dialogService.addDialog(SignupOptionsModalComponent, {},
        { closeByClickingOutside: true }).subscribe((isConfirmed) => {
          this.userManagementService.removeUser();
        }); */
        this.modalService.show(SignupOptionsModalComponent, { backdrop: Constants.MODAL_CONFIG.backdrop, class: 'signup-options'});
    }
    else {
      this.router.navigate(['/home/login']);
    }
  }


  ngOnDestroy() { }

  onRegister() {
    /* this.dialogService.addDialog(SignupOptionsModalComponent, {},
      { closeByClickingOutside: true }).subscribe((isConfirmed) => {
        this.userManagementService.removeUser();
      }); */
      this.modalService.show(SignupOptionsModalComponent, { backdrop: Constants.MODAL_CONFIG.backdrop, class: 'signup-options'});
  }

  onRegisterPage() {
    this.router.navigate(['home/register'])
  }

  onFederatedLogin(value:string){
    localStorage.removeItem('FLOW');
    const url = this.federatedService.getFederatedLoginUrl(value);
    window.open(url, "_self");
  }

  // onGoogleLogin() {
  //   localStorage.removeItem('FLOW');
  //   // const url = this.googleservice.getGoogleLoginUrl();
  //   // window.open(url, "_self");
  // }

}
