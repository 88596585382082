<!-- Please don't format this File, Format will give extra spacing in anchors tag -->
<div class="main">
  <div class="row">
    <div class="col-md-6 hidden-sm hidden-xs no-padding image-container">
      <div class="gradient">
        <div>
          <div class="content-heading">{{ 'SECURE_HOME.WELCOME_TAG' | translate }}</div>
          <ng-container *ngIf="userBrand === 'kwikset'">
          <p class="content-banner">{{ 'BRAND_SELECTION.CONTENT_HEADING_KWIKSET' | translate }}</p>
          </ng-container>
          <ng-container *ngIf="userBrand === 'weiser'">
          <p class="content-banner">{{ 'BRAND_SELECTION.CONTENT_HEADING_WEISER' | translate }}</p>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="col-md-6 parent-right-content-container">
      <div class="right-content-container">
        <div class="col-lg-12 no-padding">
          <ng-container *ngIf="isVerified">
            <div class="verify-continue">
              <div class="row">
                <p class="verify-continue-title">
                  {{ 'REGISTRATION.PHONE_VERIFICATION.TITLE' | translate }}
                </p>
                <div class="verify-tick">
                  <div class="verify-tick-img"></div>
                  <p class="verify-tick-p">{{ 'REGISTRATION.EMAIL_VERIFICATION.COMPLETED' | translate }} </p>
                </div>
                <button tabindex="1" name="logo-icon" (click)="goToHome()"
                  class="btn btn-block sp-form-button sign-up-continue-btn verify-button sp-btn-dark"
                  [disabled]="false">
                  {{ 'REGISTRATION.COLLECT_INFO.BUTTON_SUBMIT_FORM' | translate }}
                </button>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="!isVerified && this.step=='login'">
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-11 col-md-offset-1 col-lg-8 col-lg-offset-2">
                <div class="row">
                  <div class="hidden-lg">
                    <br />
                  </div>
                  <div *ngIf="errorMessage && !invalidUserPass"
                    class="alert alert-danger alert-dismissible fade in">
                    <a class="close" aria-label="close" (click)="errorMessage = ''">&times;</a>
                    {{ errorMessage }}
                  </div>
                  <div *ngIf="successMessage" class="alert alert-danger alert-info alert-dismissible fade in">
                    <a class="close" aria-label="close" (click)="successMessage = ''">&times;</a>
                    {{ successMessage }}
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-11 col-md-offset-1 col-lg-8 col-lg-offset-2">
                <form name="login" method="POST" [formGroup]="login" (ngSubmit)="onLogin()">
                  <div class="hidden-lg hidden-md">
                    <h3 class="text-center">
                      <b>{{ 'HOME.LOGIN' | translate }}</b>
                    </h3>
                  </div>
                  <div class="hidden-xs hidden-sm">
                    <h3 class="sp-form-heading">
                      {{ 'HOME.LOGIN' | translate }}
                    </h3>
                  </div>
                  <div class="form-group has-feedback">
                    <div [ngClass]="emailError.divClass">
                      <mat-form-field hideRequiredMarker class="full-width" appearance="fill">
                        <mat-label>{{'LOGIN.EMAIL.PLACEHOLDER' | translate}}</mat-label>
                        <input #mailfocus tabIndex="2" matInput id="loginEmail" type="email" maxlength="100" [(ngModel)]="email"
                          formControlName="loginEmail" (click)="onEmailEnter()" (blur)="onChanges($event)" value="{{email}}">
                      </mat-form-field>
                      <p *ngIf="emailError.req || forgotPasswordFlag === true" class="help-block">{{ 'LOGIN.EMAIL.REQUIRED' | translate }}</p>
                      <p *ngIf="emailError.invalid" class="help-block invalid-text-p"> {{ 'LOGIN.EMAIL.INVALID' |
                        translate }}</p>
                      <p style="color: red" *ngIf="errorMessage && invalidUserPass" class="help-block invalid-text-p">
                        {{ errorMessage }}</p>
                    </div>
                    <div [ngClass]="passwordError.divClass">
                      <mat-form-field hideRequiredMarker class="full-width" appearance="fill">
                        <mat-label>{{ 'LOGIN.PASSWORD.PLACEHOLDER' | translate }}</mat-label>
                        <input tabIndex="3" matInput type="password" id="loginPassword" [(ngModel)]="password"
                          formControlName="loginPassword" [type]="hidePass ? 'password' : 'text'" maxlength="20"
                          (blur)="onChanges($event)">
                        <mat-icon  tabIndex="4" class="cursor-pointer eye-focus" (click)="togglePasswordField()" (keydown.enter)="togglePasswordField()" matSuffix>{{hidePass ?
                          'visibility' : 'visibility_off'}}</mat-icon>
                      </mat-form-field>
                      <p *ngIf="passwordError.req" class="help-block">
                        {{ 'LOGIN.PASSWORD.REQUIRED' | translate }}
                      </p>
                      <p style="color: red" *ngIf="errorMessage && invalidUserPass" class="help-block invalid-text-p">{{
                        errorMessage }}</p>

                    </div>
                    <div class="form-bottom-actions">
                      <mat-slide-toggle tabIndex="5" id="rememberMe" formControlName="rememberMe">{{ 'LOGIN.REMEMBER_ME' | translate }}</mat-slide-toggle>
                      <a tabIndex="6" (click)="forgotPassword()" (keydown.enter)="forgotPassword()" class="sp-hyperlink">
                        {{ 'LOGIN.FORGOT_PASSWORD' | translate }}
                      </a>
                    </div>



                  </div>
                  <div class="row">
                    <div class="col-lg-7 col-md-7 no-padding mb-10px">
                      <br />
                      <button tabIndex="7" type="button" class="btn btn-block sp-btn-dark h-45" id="loginSubmit" type="submit"
                        [disabled]="!login.valid">
                        {{ 'LOGIN.LOGIN_BUTTON' | translate }}
                      </button>
                    </div>
                    <div class="col-lg-5 col-md-5 forgot-password-link">
                      <div class="hidden-lg hidden-md"><br /></div>
                      <a tabIndex="6" 
                        (click)="forgotPassword()" class="sp-hyperlink">
                        {{ 'LOGIN.FORGOT_PASSWORD' | translate }}
                    </a>
                    </div>
                  </div>
                  <div class="hidden-lg hidden-md">
                    <div class="sp-hyperlink-div text-center">
                      {{ 'LOGIN.NEW_ACCOUNT.PRE' | translate }}
                      <a tabIndex="10" *ngIf="googleFederation || appleFederation" (click)="onRegister()" class="sp-hyperlink">{{
                        'LOGIN.NEW_ACCOUNT.CLICK' | translate }}</a>
                      <a tabIndex="10" *ngIf="!googleFederation && !appleFederation" (click)="onRegisterPage()" class="sp-hyperlink">{{
                        'LOGIN.NEW_ACCOUNT.CLICK' | translate }}</a>
                    </div>
                    <br /><br />
                  </div>
                  <ng-container *ngIf="googleFederation">
                    <div class="row">
                      <div class="col-lg-7 col-md-7 no-padding mb-10px">
                        <button tabIndex="8" (click)="onFederatedLogin(federatedIdentities.Google)" type="button" class="btn btn-block btn-google h-45">
                          <img alt="gmail.png" class="gmail-img" height="18px" style="float: left;"
                            src="../../../assets/images/gmail-icon.png" width="17.66px">
                          {{'LOGIN.LOGIN_WITH_GOOGLE_BUTTON' | translate}}
                        </button>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="appleFederation">
                    <div class="row">
                      <div class="col-lg-7 col-md-7 no-padding">
                        <button tabIndex="9" (click)="onFederatedLogin(federatedIdentities.Apple)" type="button" class="btn btn-block btn-apple h-45">
                          <img alt="apple.png" class="apple-img" height="18px" style="float: left;"
                            src="../../../assets/images/apple.svg" width="17.66px">
                          {{'LOGIN.LOGIN_WITH_APPLE_BUTTON' | translate}}
                        </button>
                      </div>
                    </div>
                  </ng-container>

                  <div class="hidden-lg hidden-md">
                    <br /><br />
                  </div>

                  <div class="hidden-sm hidden-xs sp-hyperlink-div">
                    {{ 'LOGIN.NEW_ACCOUNT.PRE' | translate }}
                    <a tabIndex="10" *ngIf="googleFederation || appleFederation" (click)="onRegister()" (keydown.enter)="onRegister()" class="sp-hyperlink">{{ 'LOGIN.NEW_ACCOUNT.CLICK'
                      | translate }}</a>
                    <a tabIndex="10" *ngIf="!googleFederation && !appleFederation" (click)="onRegisterPage()" (keydown.enter)="onRegisterPage()" class="sp-hyperlink">{{
                      'LOGIN.NEW_ACCOUNT.CLICK' | translate }}</a>
                  </div>
                  <div class="hidden-lg">
                    <br />
                  </div>
                </form>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="!isVerified && this.step == 'code'">
            <div class="col-xs-12">
              <div class="row">
                <div class="hidden-lg hidden-md">
                  <br />
                </div>
                <div class="col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2 col-sm-6 col-sm-offset-3 col-xs-12">
                  <div class="row">
                    <div *ngIf="errorMessage && !otpError" class="alert alert-danger alert-dismissible fade in">
                      <a class="close" aria-label="close" (click)="errorMessage = ''">&times;</a>
                      {{ errorMessage }}
                    </div>
                  </div>
                </div>
              </div>
              <form id="signinverify-form" class="
                  col-md-10
                  col-md-offset-1
                  col-lg-8
                  col-lg-offset-2
                  col-sm-6
                  col-sm-offset-3
                  col-xs-12
                " method="POST" [formGroup]="loginverify" (ngSubmit)="onCodeConfirm()">
                <h2 *ngIf="this.choice === 'email' && this.isPhoneVerified =='true'"
                  class="sp-form-heading phone-heading">
                  {{ 'LOGIN.VERIFICATION.PHONE_TITLE' | translate }}
                </h2>

                <h2 *ngIf="this.choice === 'email' && this.isPhoneVerified !=='true' "
                  class="sp-form-heading phone-heading">
                  {{ 'LOGIN.VERIFICATION.PHONE_TITLE' |
                  translate }}
                </h2>
                <h2 *ngIf="this.choice === 'phone' " class="sp-form-heading phone-heading">
                  <p class="mobile-text-align">{{ 'LOGIN.VERIFICATION.PHONE_TITLE' | translate }}</p>
                </h2>

                <ng-container *ngIf="!isLoaderActive">
                  <ng-container *ngIf="this.choice === 'email'">
                    <p class="mobile-text-align">
                      {{ ('REGISTRATION.EMAIL_VERIFICATION.SUB_TITLE' | translate).replace('[EMAIL_ADDRESS]', '')}}
                      <b class="bold-flex">
                        <div class="email-truncated">{{(login.get('loginEmail').value.split('@')[0]).toLowerCase()}}
                        </div>
                        <div>@{{(login.get('loginEmail').value.split('@')[1]).toLowerCase()}}.</div>
                      </b>
                    </p>
                    <br />
                  </ng-container>

                  <ng-container *ngIf="this.choice === 'phone'">
                    <p class="mobile-text-align">
                      {{ ('REGISTRATION.PHONE_VERIFICATION.SUB_TITLE' | translate) }}<b class="bold-flex">{{lastTwoDigitsOfPhone}}</b>.
                    </p>
                    <br />
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="isLoaderActive">
                  <ng-container *ngIf="this.choice === 'email'">
                    <p class="mobile-text-align">
                      {{ ('REGISTRATION.EMAIL_VERIFICATION.WAIT_MESSAGE' | translate) }}
                    </p>
                    <br />
                  </ng-container>

                  <ng-container *ngIf="this.choice === 'phone'">
                    <p class="mobile-text-align">
                      {{ ('REGISTRATION.PHONE_VERIFICATION.WAIT_MESSAGE' | translate ) }}
                    </p>
                    <br />
                  </ng-container>
                </ng-container>


                <div [hidden]="isLoaderActive">
                  <div class="form-group has-feedback">
                    <div [ngClass]="codeError.divClass">
                      <div class="ngx-otp">
                        <ng-otp-input #ngOtpInput (onInputChange)="handleFillEvent($event)" [config]="ngconfig"
                          id="ng-otp-input" *ngIf="showOtpComponent"></ng-otp-input>
                      </div>
                      <p style="color: red; margin-top:15px;" *ngIf="otpError" class="help-block invalid-otp">{{
                        errorMessage }}</p>

                      <p class="mobile-text-align" *ngIf="codeError.req" class="help-block">{{ 'LOGIN.CODE.REQUIRED' |
                        translate}}</p>
                    </div>
                  </div>
                  <br />

                  <ng-container *ngIf="this.choice === 'phone'">
                    <p class="mobile-text-align">
                      {{ 'REGISTRATION.PHONE_VERIFICATION.RE_SEND_CODE' |
                      translate }}
                      <a tabIndex="0" class="sp-hyperlink sp-resend" (keydown.enter)="onResendCode()" (click)="onResendCode()">{{
                        'LOGIN.VERIFICATION.LINK_RESEND_CODE' | translate }}.</a>
                    </p>
                  </ng-container>
                  <ng-container *ngIf="this.choice === 'phone'">
                    <p class="mobile-text-align hidden-lg hidden-md">
                      {{ 'LOGIN.VERIFICATION.OR' | translate }}
                    </p>
                    <p class="mobile-text-align">
                      <span class="hidden-xs hidden-sm">{{ 'LOGIN.VERIFICATION.OR' | translate }}</span>
                      <a tabIndex="0" class="sp-hyperlink sp-resend" (keydown.enter)="onSendCodeToEmail()" (click)="onSendCodeToEmail()">{{ 'LOGIN.VERIFICATION.SEND_CODE'
                        | translate }}</a>
                      {{ 'LOGIN.VERIFICATION.TO_MY_EMAIL' | translate }}
                    </p>
                  </ng-container>
                  <ng-container *ngIf="this.choice === 'email'">
                    <p class="mobile-text-align">
                      {{ 'REGISTRATION.EMAIL_VERIFICATION.RE_SEND_CODE' |
                      translate }}
                      <a class="sp-hyperlink sp-resend" tabIndex="0" (keydown.enter)="onResendCode()" (click)="onResendCode()">{{
                        'LOGIN.VERIFICATION.LINK_RESEND_CODE' | translate }}.</a>
                    </p>
                  </ng-container>
                  <ng-container *ngIf="this.choice === 'email' && this.isPhoneVerified == 'true'">
                    <p class="mobile-text-align hidden-lg hidden-md">
                      {{ 'LOGIN.VERIFICATION.OR' | translate }}
                    </p>
                    <p class="mobile-text-align">
                      <span class="hidden-xs hidden-sm">{{ 'LOGIN.VERIFICATION.OR' | translate }}</span>
                      <a class="sp-hyperlink sp-resend" tabIndex="0" (keydown.enter)="onSendCodeToPhone()" (click)="onSendCodeToPhone()">{{'LOGIN.VERIFICATION.SEND_CODE' |
                        translate}}</a>
                      {{ 'LOGIN.VERIFICATION.TO_MY_PHONE' | translate }}
                    </p>
                  </ng-container>
                </div>
                <div *ngIf="isLoaderActive">
                  <app-inner-loader></app-inner-loader>
                </div>
              </form>
            </div>
          </ng-container>
          <ng-container *ngIf="this.user && !this.user.phoneActive">


            <div class="col-xs-12">
              <div class="row">
                <div class="hidden-lg hidden-md">
                  <br />
                </div>
                <div class="col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2 col-sm-6 col-sm-offset-3 col-xs-12">
                  <div class="row">
                    <div *ngIf="errorMessage" class="alert alert-danger alert-dismissible fade in">
                      <a class="close" aria-label="close" (click)="errorMessage = ''">&times;</a>
                      {{ errorMessage }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-11 col-md-offset-1 col-lg-8 col-lg-offset-2">
                <app-edit-profile-phone-verification [parent]='this'></app-edit-profile-phone-verification>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>