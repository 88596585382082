    <div class="modal-header">
  <button type="button" (click)="bsModalRef.hide()" class="close">&times;</button>
</div>
<div class="modal-body">
  <div class="sp-middle-element">
    <div class="col-md-12 col-lg-10 col-sm-8">
      <div class="col-md-12">
        <div class="row">
          <div class="row">
              <h3 style="margin-left: 35px">{{'WEB_ACCESSIBILITY.TITLE' | translate}}</h3>
          </div>
          <div class="row">
            <div class="col-md-12 col-lg-12">
              <h5 class="col-md">{{'WEB_ACCESSIBILITY.HEADER' | translate}}</h5>
            </div>
          </div>
          <br>
          <div class="row">
            <div class="col-md-12 col-lg-12">
              <p class="col-md webpara">
                {{'WEB_ACCESSIBILITY.MESSAGE' | translate}}&nbsp;<a class="sp-hyperlink" href="mailto:{{email}}?subject=GoConcourse%20Website:%20Web%20Accessibility">{{email}}</a>&nbsp;{{'WEB_ACCESSIBILITY.MESSAGE_2' | translate}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
</div>