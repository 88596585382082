import { Component, OnInit, ViewChild, EventEmitter, Output, OnDestroy, AfterViewInit, ElementRef } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { UserLoginService } from "../../../services/user-login.service";
import { LoginCognitoCallback, LoggedInCallback } from "../../../services/utils/cognito.service";
import { TranslateService } from '@ngx-translate/core';
import { Logger } from '../../../services/utils/logger'
import { NgRedux, select } from '@angular-redux/store';
import { IAppState } from '../../../services/utils/state-management/store';
import { RESET_REGISTRATION } from '../../../services/utils/state-management/actions';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserManagement } from "../../../services/utils/state-management/action-classes";
import { IForgotPassword, IUser } from "../../../services/utils/state-management/types";
import { Utility, spaceCheck } from "../../../services/utils/utility";
import { LoggingLevel } from "../../../services/utils/logging-level";
import { ComponentCanDeactivate } from "../../../guards/deactivate/ComponentCanDeactivate";
import { SignupOptionsModalComponent } from '../../register/signup-options-modal/signup-options-modal.component';
import { CookieService } from 'ngx-cookie-service';
import { FederatedManagementService } from "../../../services/federated-management.service";
import { UserProfileService } from "../../../services/user-profile.service";
import { ForgotPasswordService } from "../../../services/forgot-password.service";
import { DataShareService } from "../../../services/data-share.service";
import { ApiRequestService } from "src/app/services/api-client/api-requests.service";
import { Constants } from "src/app/services/utils/Constants";
import apiConfig from "src/app/services/api-client/api-config";
import { ResponseHandlerService } from "src/app/services/utils/response-handler.service";
import { Config } from "src/app/services/utils/Config";
import { LanguageChangeCallback, LanguageChangeService } from "src/app/services/language-change.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";


@Component({
  selector: 'consumer-portal-login',
  templateUrl: './login.html',
  styleUrls: ['./login.css', '../../../../assets/css/ng-otp.css'],
})


export class LoginComponent extends ComponentCanDeactivate implements LoginCognitoCallback, LoggedInCallback, LanguageChangeCallback, OnInit, OnDestroy, AfterViewInit {

  @Output() onError = new EventEmitter<any>();
  @select() regStep;
  @ViewChild('ngxotp') ngxOtp;
  @ViewChild('ngOtpInput') ngOtpInput;
  @ViewChild('mailfocus') mailFocus: ElementRef;

  changed: boolean = false;
  login: FormGroup;
  logincode: FormGroup;
  loginverify: FormGroup;
  user: IUser;
  spaceCheck = spaceCheck;
  medium = 'phone';

  emailError = {
    divClass: '',
    req: false,
    invalid: false
  };
  codeError = {
    divClass: '',
    req: false
  };
  passwordError = {
    divClass: '',
    req: false
  };

  email = '';
  enteredEmail = '';
  password = '';
  isRememberMeEnabled = false;
  errorMessage: string = '';
  successMessage: string = '';
  attempt: number;
  step = 'login';
  mfaStep = false;
  choice = "phone";
  mfaData = {
    destination: '',
    callback: null
  };
  isPhoneVerified = 'false';
  image: string;
  resend: boolean;
  resendAttempt: number;
  // to set mat-slider state to false
  isChecked = false;
  hidePass: boolean = true;
  cookieValue: string;
  isCodeConfirmed: boolean = false;
  lastTwoDigitsOfPhone: any;

  //ng-otp-input
  showOtpComponent = true;
  ngconfig = Utility.getNgxOtpConfigObject();


  federatedIdentities = Config.FEDERATED_IDENTITIES;

  userBrand: string;
  securityCodePaste: string;
  securityCodeEnter = [];
  securityCode: string;
  securityCondition: boolean = false;
  isVerified: boolean = false;
  otpError: boolean = false;
  limitExceed: boolean = false;
  invalidUserPass: boolean = false;
  sessionTimeout: boolean = false;
  isLoaderActive: boolean = false;
  isLogin: boolean = false;
  googleFederation: boolean;
  appleFederation: boolean;
  config: Object;
  securityQuestionsFlag: any;
  forgotPasswordFlag: boolean;
  modalRef: BsModalRef;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public userService: UserLoginService,
    public federatedService: FederatedManagementService,
    public translate: TranslateService,
    public ngRedux: NgRedux<IAppState>,
    public apiService: ApiRequestService,
    public formBuilder: FormBuilder,
    private logger: Logger,
    public userManagementService: UserManagement,
    private utility: Utility,
    private modalService: BsModalService,
    public cookieService: CookieService,
    public userProfileService: UserProfileService,
    public forgotPasswordService: ForgotPasswordService,
    public responseHandler: ResponseHandlerService,
    private dataservice: DataShareService,
    private languageService: LanguageChangeService
  ) {
    super();
    this.userProfileService.getUserData().subscribe(data => {
      this.isVerified = data.isVerified;
      this.user = data.user;
    });
    let configObject = JSON.parse(localStorage.getItem('config' || ''));
    this.googleFederation = configObject && configObject.Feature.GoogleFederation;
    this.appleFederation = configObject && configObject.Feature.AppleFederation;
    this.securityQuestionsFlag = configObject && configObject.Feature.SecurityQuestions
  }

  /**
  * This method initializes the component after the view has been fully initialized
  */
  ngAfterViewInit() {
  // Access the 'mailFocus' view child element and give it focus
    this.mailFocus.nativeElement.focus();
  }

  canDeactivate() {
    this.config = JSON.parse(localStorage.getItem('state') || '');
    if (this.config['userObject'] && !this.config['userObject']['phoneActive']) {
      return false;
    } else if (this.step === 'login' || this.step === '') {
      return true;
    } else {
      return false;
    }
  }


  ngOnInit() {
    this.userBrand = localStorage.getItem("userBrand");
    this.isLogin = window.location.href.endsWith('login');
    localStorage.removeItem('PhoneVerified');
    //Reset flow key
    localStorage.removeItem('FLOW');
    // adding clearing of session and localstorage for if someone abandons the federation flow, their token is not cleared
    // when canDeactivate returns false, removed the user and bring them on login page. In add a phone page user save in storage and not navigate to login page when click on logo
    if (this.ngRedux.getState().userObject === null || this.canDeactivate() === false) {
      sessionStorage.clear();
      Object.keys(localStorage).map(key => {
        if (key.startsWith('CognitoIdentityServiceProvider')) {
          localStorage.removeItem(key);
        }
      })
    }
    // error message in case of federated user does not exist in system
    let federatedUserErrorMessage = this.ngRedux.getState().errorMessage;
    if (federatedUserErrorMessage) {
      this.invalidUserPass = false;
      this.errorMessage = federatedUserErrorMessage
    }
    const queryParamStep = this.route.snapshot.queryParams['step'];
    const FederatedStep = Config.PROVIDERS.FEDERATED;
    if (queryParamStep === FederatedStep) {
      this.step = '';
    }
    else {

      this.errorMessage = this.utility.getErrorMessage();
      this.successMessage = this.utility.getSuccessMessage();
      this.ngRedux.dispatch({ type: RESET_REGISTRATION });

    }
    this.dataservice.currentValue.subscribe((value) => {
      if (value.error) {
        this.translate.get('REGISTRATION.EMAIL_VERIFICATION.ERROR.ALREADY_EXIST').subscribe(value => this.errorMessage = value);
      }
    })
    this.changed = false;
    Utility.focusLogo();
    // fetching email value from the cookie
    this.cookieValue = this.cookieService.get('email');
    this.login = this.formBuilder.group({
      loginEmail: [null, [Validators.required, Validators.pattern("^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$")]],
      loginPassword: [null, Validators.required],
      rememberMe: ''
    });
    // Setting email value from cookie if remember me is checked in previous login
    if (this.cookieValue) {
      this.email = this.cookieValue;
      this.login.get('rememberMe').setValue(true);
    }
    this.logincode = this.formBuilder.group({
      choice: [null, null]
    });
    this.loginverify = this.formBuilder.group({
      securityCode: [null, Validators.required]
    });
    this.logger.log(LoggingLevel.DEBUG, "home", "Checking if the user is already authenticated. If so, then redirect to the secure site");
    // this.image = './../../../../assets/images/login/bg' + (Math.floor((Math.random() * 100)) % 8 + 1) + '.jpg';
    this.image = './../../../assets/images/landing/Rectangle_794.jpg';
    this.userService.isAuthenticated(this);
  }

  orientationChangeHandler = () => {
    if (screen.orientation.type == "landscape-primary" && screen.orientation.angle != 0)
      document.body.classList.remove("main-class");
    else
      document.body.setAttribute('class', 'main-class');
  }

  ngOnDestroy() {
    // adding main class to body to hide overflow
    document.body.classList.remove("main-class");
  }


  onChanges($event) {
    // HALOC-3955: Add this check for email input field,
    // signup modal will open on second click, 
    // at first click it will check the validations on the field.
    if (this.login.pristine) {
      return;
    }
    if ($event == null || $event.target.id === 'loginEmail') {
      if (!this.login.controls['loginEmail'].valid) {
        this.emailError.divClass = 'has-error';
        if (this.login.controls['loginEmail'].errors['required']) {
          this.emailError.req = true;
          this.emailError.invalid = false;
        } else {
          this.emailError.req = false;
          this.emailError.invalid = true;
        }
      } else {
        this.emailError.divClass = 'has-success';
        this.emailError.req = false;
        this.emailError.invalid = false;
      }
    }
    if ($event == null || $event.target.id === 'loginPassword') {
      if (!this.login.controls['loginPassword'].valid) {
        this.passwordError.divClass = 'has-error';
        this.passwordError.req = true;
      } else {
        this.passwordError.divClass = 'has-success';
        this.passwordError.req = false;
      }
    } else if ($event.target.id === 'securityCode') {
      if (!this.loginverify.controls['securityCode'].valid) {
        this.codeError.divClass = 'has-error';
        this.codeError.req = true;
      } else {
        this.codeError.divClass = 'has-success';
        this.codeError.req = false;
      }
    }
  }

  goToHome() {
    this.router.navigate(['/securehome']);
  }

  onRegister() {
    /* this.dialogService.addDialog(SignupOptionsModalComponent, {},
      { closeByClickingOutside: true }).subscribe((isConfirmed) => {
        this.userManagementService.removeUser();
      }); */

    this.emailError.invalid = false
    this.emailError.req = false
    this.login = this.formBuilder.group({
      loginEmail: [null, null]
    })

    const initialState = {
      invalidEmail: this.emailError.invalid,
      requireEmail: this.emailError.req,
      login: this.login
    }
    localStorage.setItem('PROVIDER', Config.PROVIDERS.FEDERATED);
    this.modalRef = this.modalService.show(SignupOptionsModalComponent,
      { initialState: initialState, backdrop: Constants.MODAL_CONFIG.backdrop, class: 'signup-options' });
    this.modalRef.content.event.subscribe(confirmedData => {
      if (confirmedData) {
        this.login.markAllAsTouched();
        this.onChanges(null);
      }
    })
    this.login = this.formBuilder.group({
      loginEmail: [null, [Validators.required, Validators.pattern("^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$")]]
    });
  }

  onRegisterPage() {
    this.router.navigate(['home/register']);
  }

  onLogin() {
    this.changed = true;

    //@TODO: Add validator service here
    this.successMessage = null;

    if (!this.login.valid) {
      this.onChanges(null);
      return;
    }
    this.errorMessage = null;
    this.enteredEmail = this.email;
    this.userService.authenticate(this.email ? this.email.toLowerCase() : this.email, this.password, this);
  }

  //checking remaining
  onSendCode() {
    // this.isLoaderActive = true;
    this.resetOTP();
    var res = {
      answerType: 'generateCode',
      medium: 'login',
      codeType: this.isPhoneVerified == 'true' ? 'phone' : 'email'
    }
    if (this.mfaStep)
      this.mfaData.callback(null, res);
  }

  onResendCode() {

    //ng-otp-input
    this.resetOTP();

    this.otpError = false;
    this.errorMessage = null;
    var res = {
      answerType: 'regenerateCode',
      medium: 'login',
      codeType: this.choice
    }
    this.resend = true;
    this.resendAttempt = this.resendAttempt + 1;
    if (this.mfaStep) {
      this.mfaData.callback(null, res);
    }
  }

  onSendCodeToEmail() {
    // this.isLoaderActive = true;
    this.resetOTP();

    //ng-otp-input
    this.showOtpComponent = false;
    this.securityCodePaste = null;
    setTimeout(() => {
      this.showOtpComponent = true;
    }, 0);

    var res = {
      answerType: 'regenerateCode',
      medium: 'login',
      codeType: 'email'
    }
    this.choice = 'email';
    this.resend = true;
    this.resendAttempt = 0;
    if (this.mfaStep) {
      this.mfaData.callback(null, res);
    }
  }

  onSendCodeToPhone() {
    // this.isLoaderActive = true;
    this.resetOTP();

    this.showOtpComponent = false;
    this.securityCodePaste = null;
    setTimeout(() => {
      this.showOtpComponent = true;
    }, 0);

    var res = {
      answerType: 'regenerateCode',
      medium: 'login',
      codeType: 'phone'
    }
    this.choice = 'phone';
    this.resend = true;
    this.resendAttempt = 0;
    if (this.mfaStep) {
      this.mfaData.callback(null, res);
    }
  }

  //ng-otp-input
  handleFillEvent(value: string): void {
    this.securityCodePaste = value;
    if (this.securityCodePaste.length === 6 && !this.securityCondition) {
      this.securityCode = this.securityCodePaste;
      this.securityCondition = true;
      this.onCodeConfirm();
    }
  }

  resetOTP() {
    this.showOtpComponent = false;
    this.securityCodePaste = null;
    setTimeout(() => {
      this.showOtpComponent = true;
    }, 0);
  }

  onCodeConfirm() {
    this.errorMessage = null;
    this.isLoaderActive = true;
    if (!this.securityCode) {
      this.codeError.divClass = 'has-error';
      this.codeError.req = true;
    } else {
      let res = {
        answerType: 'verifyCode',
        medium: 'login',
        codeType: this.choice,
        code: this.securityCode.trim()
      };
      if (this.mfaStep) {
        this.mfaData.callback(null, res);
      }

    }
  }

  onSelectionChange(choice) {
    this.choice = choice;
  }

  /* This gets called when cognito login is succesful or fails */
  cognitoCallback(message: string, result: any) {
    this.errorMessage = null;
    this.limitExceed = false;
    this.invalidUserPass = false;
    this.sessionTimeout = false;
    this.otpError = false;
    this.isLoaderActive = false;
    if (message != null) { //error
      this.password = "";
      this.login.controls['loginPassword'].reset();
      this.login.controls['loginPassword'].markAsPristine();
      this.passwordError.divClass = "";
      if (message === 'User is not confirmed.') {
        this.router.navigate(['/home/confirmRegistration', this.email]);
      } else if (message === 'User needs to set password.') {
        this.router.navigate(['/home/newPassword']);
      } else if (message.indexOf(Constants.ERROR_CODES.CODE_VERIFY_LIMIT_EXCEED) > -1) {
        this.translate.get('LOGIN.ERROR.RETRIES_EXCEEDED').subscribe(value => this.errorMessage = value);
        this.limitExceed = true;
        this.attempt = 0;
        this.securityCondition = false;
        this.resend = false;
        this.login.controls['loginEmail'].reset();
        this.router.navigate(['/home/login']);
        this.login.get('rememberMe').setValue(false);
      } else if (message.indexOf(Constants.ERROR_CODES.INVALID_SESSION) > -1) {
        this.login = this.formBuilder.group({
          loginEmail: [null, [Validators.required, Validators.pattern("^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$")]],
          loginPassword: [null, Validators.required],
          rememberMe: ''
        });
        this.translate.get('LOGIN.ERROR.SESSION_TIMEOUT').subscribe(value => this.errorMessage = value);
        this.sessionTimeout = true;
        this.attempt = 0;
        this.securityCondition = false;
        this.resend = false;
        this.router.navigate(['/home/login']);
      } else {
        this.invalidUserPass = true;
        this.translate.get('LOGIN.ERROR.INCORRECT_USERNAME_AND_PASSWORD').subscribe(value => this.errorMessage = value);
      }
      this.step = 'login';
    } else { //success
      this.changed = false;
      this.apiService.callApi(Constants.API_METHODS.GET, apiConfig.me.uri, null).subscribe({
        next: (res) => {
          console.log(res, JSON.stringify(res));
          if (res && res.data && res.data.length > 0) {
            // HALOC-3023: Setting User Preferred Language After Login Success
            const preferredLanguage = this.ngRedux.getState().preferredLanguage;
            this.languageService.updateUserLanguage(true, preferredLanguage, this);
            res.data[0]['preferredlanguage'] = preferredLanguage;
            this.user = this.userManagementService.parseUserObjectAndDispatch(res.data[0]);
            if (this.user.phoneActive) {
              // Checking if the remember me value is true or false
              // if the value is "true" a cookie with name email will be saved in cookies
              if (this.login.controls['rememberMe'].value === true) {
                document.cookie = "email=" + this.user.email + "; max-age=" + Config.COOKIE_MAX_AGE + "; SameSite=Lax; secure";
              }
              else {
                document.cookie = "email=;max-age=";
              }
            } else {
              this.userManagementService.removeUserPhoneNumber();
            }
            this.step = '';
          }
          else {
            if (res && res['type'] === Constants.ERROR_CODES.RESOURCE_NOT_FOUND) {
              //user not found, proceed to signup
              this.userManagementService.removeUser();
              this.router.navigate(['/home/register']);
            }
            else {
              this.router.navigate(['/home']);
            }
          }

        },
        error: (err) => {
          let data = this.responseHandler.parseErrorResponseV2(err);

        },
      })

      /* this.apiService.makeCall(null, 'get', apiConfig.me, null, (err, res) => {
        if (res && res.success && res.data && res.data.length > 0) {
          this.user = this.userManagementService.parseUserObjectAndDispatch(res.data[0]);

          if (this.user.phoneActive) {
            // Checking if the remember me value is true or false
            // if the value is "true" a cookie with name email will be saved in cookies
            if (this.login.controls['rememberMe'].value === true) {
              document.cookie = "email=" + this.user.email + "; max-age=" + Config.COOKIE_MAX_AGE + "; SameSite=Lax; secure";
            }
            else{
              document.cookie = "email=;max-age=";
            }
            // HALOC-3023: Setting User Preferred Language After Login Success
            const preferredLanguage = this.ngRedux.getState().preferredLanguage;
            this.languageService.updateUserLanguage(true, preferredLanguage, this);
          } else {
            this.userManagementService.removeUserPhoneNumber();
          }
          this.step = '';
        }
        else {
          if (res && res['type'] === errorCodes.RESOURCE_NOT_FOUND) {
            //user not found, proceed to signup
            this.userManagementService.removeUser();
            this.router.navigate(['/home/register']);
          }
          else {
            this.router.navigate(['/home']);
          }
        }
      }); */
    }
  }

  /* Handle MFA logic */
  handleMFAStep(challengeParameters: string, callback: (err: any, res: any) => any): void {
    this.isLoaderActive = false;
    this.otpError = false;
    this.invalidUserPass = false;
    this.limitExceed = false;
    this.sessionTimeout = false;
    this.errorMessage = null;
    // sets choice property on the basis of cognito response if number is verified or not to select mediumtype
    if (challengeParameters.hasOwnProperty("phoneNumberVerified")) {
      this.choice =
        challengeParameters["phoneNumberVerified"] == "true"
          ? "phone"
          : "email";
      this.lastTwoDigitsOfPhone = challengeParameters['phoneNumberSuffix'];
    }

    this.email = "";
    this.password = "";
    Utility.focusLogo();
    this.errorMessage = null;
    this.step = challengeParameters['challengeType'];
    if (challengeParameters['phoneNumberVerified'] && challengeParameters['phoneNumberVerified'] === 'true') {
      this.isPhoneVerified = 'true';
    } else if (challengeParameters['phoneNumberVerified']) {
      this.isPhoneVerified = 'false';
    }

    this.mfaStep = true;
    this.mfaData.callback = callback;

    if (this.step == 'medium') {
      this.onSendCode()
    }

    if (this.step.includes("code")) {
      this.attempt = parseInt(this.step.charAt(4));
      this.step = 'code';
      this.loginverify.controls['securityCode'].reset();
      this.securityCode = "";
    }
    if (this.attempt >= 1) {
      if (this.resend) {
        if (this.resendAttempt === 0) {
          this.translate.get('HOME.ERROR.CODE_SEND').subscribe(value => this.errorMessage = value);
        }
        else {
          this.translate.get('HOME.ERROR.CODE_RESEND').subscribe(value => this.errorMessage = value);
        }
        this.resend = false;
      } else {
        this.translate.get('HOME.ERROR.INCORRECT_CODE').subscribe(value => this.errorMessage = value);
        this.otpError = true;

        //ng-otp-input
        this.resetOTP();

        this.securityCondition = false;
      }
    }
  }

  getUserData() {
    return this.userManagementService.getUserObject();
  }

  /* Redirect user to secured home page if the user is already logged in */
  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (isLoggedIn) {
      this.user = this.userManagementService.getUser();
      if (this.user) {
        if (this.user.phoneActive) {
          this.router.navigate(['/securehome']);
        } else {
          this.step = '';
        }
      }
    }
  }

  forgotPassword() {
    let forgotPassword: IForgotPassword = this.forgotPasswordService.getForgotPasswordObject();
    forgotPassword.step = 0;
    forgotPassword.email = this.email && this.email.toLowerCase();
    forgotPassword.verificationType = this.medium;
    forgotPassword.phoneNumber = null;
    forgotPassword.secretQuestions = null;
    forgotPassword.secretAnswer = null;
    forgotPassword.verificationCode = null;
    forgotPassword.phoneActive = true;
    this.forgotPasswordService.updateForgotPasswordObject(forgotPassword);
    if (this.securityQuestionsFlag) {
      if (this.login.controls['loginEmail'].valid) {
        this.forgotPasswordService.getSecurityQuestions(this.email.toLowerCase(), this);
        this.router.navigate(["/forgotPassword"]);
      }
    } else {
      this.sendForgotPasswordCode();
    }
    if (this.email === '' || null) {
      this.forgotPasswordFlag = true;
    }
  }

  onEmailEnter() {
    this.forgotPasswordFlag = false;
  }

  sendForgotPasswordCode() {
    this.forgotPasswordService.callApiToSendCode('phone', this);
  }

  sendVerifiactionCodeFailure() {
    this.translate.get('FORGOT_PASSWORD.ENTER_VERIFICATION_CODE.ERROR.COMMON').subscribe((value) => {
      this.onError.emit(value);
    })

  }

  sendVerifiactionCodeErrorResponse(res) {
    // res = this.responseHandler.getErrorMessage('FORGOT_PASSWORD.ENTER_VERIFICATION_CODE.ERROR', res);
    let errMessg = '';
    let errorMessage = '';
    for (errMessg of res) {
      errorMessage += errMessg + ', ';
    }
    errorMessage = errorMessage.substring(0, errorMessage.length - 2);
    if (errorMessage.slice(-1) !== ".") {
      errorMessage += ".";
    }
    this.onError.emit(errorMessage);
    this.errorMessage = errorMessage
  }

  sendVerifiactionCodeSuccess() {
    this.router.navigate(["/forgotPassword"]);
    this.translate.get('FORGOT_PASSWORD.SELECT_VERIFICATION_TYPE.RESEND_CODE.SUCCESS').subscribe((value) => {
      this.onError.emit(value);
    })

  }

  logout() {
    this.userService.logout();
    window.location.reload();
  }

  updateCallback() {
    // this.router.navigate(["/securehome"]);
  }

  togglePasswordField() {
    this.hidePass = !this.hidePass;
  }

  // onGoogleLogin() {
  //   localStorage.removeItem('FLOW');
  //   const url = this.googleservice.getGoogleLoginUrl();
  //   window.open(url, "_self");

  // }

  onFederatedLogin(value: string) {
    localStorage.removeItem('FLOW');
    const url = this.federatedService.getFederatedLoginUrl(value);
    window.open(url, "_self");
  }

  onSuccessSendCode() {
    if (this.user && this.user.phoneActive) {
      this.router.navigate(['/securehome']);
    }
  }

  onFailure() {
    return;
  }

}