import { NgRedux } from '@angular-redux/store';
import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IAppState } from 'src/app/services/utils/state-management/store';

@Component({
  selector: 'app-version-info',
  templateUrl: './version-info.component.html',
  styleUrls: ['./version-info.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class VersionInfoComponent {
  title: string;

  webVersion = "0.3.147.0";
  webDate = "February 27th, 2025";
  webDescription = "Sprint 147 Week Two";
 
  
  apiVersion = "0.3.147.0";
  apiDate = "February 27th, 2025";
  apiDescription = "Sprint 147 Week Two";

  
  url = "https://kwikset.atlassian.net/wiki/spaces/SKY/pages/672235534/Version+0.0.15.0";

  constructor(
    public bsModalRef: BsModalRef,
    public translate: TranslateService,
    private ngRedux: NgRedux<IAppState>
  ) { }

  ngOnInIt() {
    this.translate.use(this.ngRedux.getState().preferredLanguage)
  }
}
